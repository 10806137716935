import axios from "axios";
import { API_URL, AUTHORIZATION } from "../../../config";

export async function getExpressCup(param)
{
    const response = await axios.get(`${API_URL}/expressCupResults?lastKnownId=${param}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    return response.data;
}