import React, { useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import axios from "axios";
import { API_URL } from "../config";


const AuthProvider = ({ children }) => {
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState({name:'', last_acess:''});


  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('TOKEN_APP_FUT_REIS_TIPS');
    setUser({name:'', last_acess:''});
  };

  async function verifieldLogin()
  {
      const token = localStorage.getItem('TOKEN_APP_FUT_REIS_TIPS');

      if(token)
      {
          try {
              const response = await axios.get(`${API_URL}/auths?token=${token}`);
  
              if(response.data)
              {
                  setUser(response.data);
                  login();
                  return response.data.status;
              }
  
          } catch (error) {
              logout();

          }

          return
      }
      logout();

  }

  useEffect(()=>{
    verifieldLogin()
  },[])

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login,  logout, setUser, verifieldLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
