import { useContext, useEffect, useState } from "react";
import { Container, Text } from "../../../componets/styles";
import HourHelp from "../../../helpers/HourHelp";
import { BoxIndicator, BoxIndicatorMobile, BtnCheckIndicator, BtnCheckIndicatorMobile, BtnMinutes, BtnMinutesMobile, ContainerGradient, ContainerGradientMobile, ContainerOpacity, ImgChanpionship } from "../style";
import { API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES, AUTHORIZATION } from "../../../config";
import axios from "axios";

import { GiSoccerBall } from "react-icons/gi";
import TooltipComponent from "./tooltip";
import TooltipComponentMobile from "./tooltipMobile";
import ParamContext from "../../../context/ParamContext";

export default function SouthAmericanSuperLeagueMobile({

    strategyComponent, 
    setReload, 
    showComponent, 
    arrayColorSelect,
    setArrayColorSelect,
    hourFilterComponent

})

{
    const [goalsVertical, setGoalsVertical] = useState([]);
    const [percentualVertical, setPercentualVertical] = useState([]);
    const [buttonsMinutesVertical, setButtonsMinutesVertical] = useState([]);
    const [arrayMinutesColorSelect, setMinutesColorSelect] = useState([]);
    const [barraPercentage, setBarraPercentage] = useState(null)

    const {
        dataSuperLeague, setDataSuperLeague, 
        dataHourSuperLeague, setDataHourSuperLeague,
        allResultsSuperLeague, setAllResultsSuperLeague, 
        lastKnownIdSuperLeague, setLastKnownIdSuperLeague,
        percentageSuperLeague, setPercentegeSuperLeague
    } = useContext(ParamContext);


    useEffect(()=>{

        const generateArray = (startMinute, step) => {
            return Array.from({ length: 20 }, (_, index) => {
              const minute = startMinute + index * step;
              return {
                id: minute / step + 1,
                goals: 0,
                minutes: minute.toString().padStart(2, '0'),
                width: '4.34%'
              };
            });
          };
        
          let newPercentualVertical = [];
          let newButtonsMinutesVertical = [];
        
          newPercentualVertical = generateArray(1, 3);
          newButtonsMinutesVertical = generateArray(1, 3);
        
          setPercentualVertical(newPercentualVertical);
          setButtonsMinutesVertical(newButtonsMinutesVertical);
          setGoalsVertical(newPercentualVertical)

    },[])


    function evaluatePercentage(percentageStr) {
        // Removendo o sinal de porcentagem e convertendo para número
        const percentage = parseFloat(percentageStr.replace('%', ''));
    
        if (percentage <= 50) {
            return "#ef4444";
        } else if (percentage > 50 && percentage <= 60) {
            return "#fb923c";
        } else {
            return "#86efac";
        }
    }


    function calculateGreenPercentage(dataArray, targetMinutes) {

        let totalMinutes = 0;
        let greenMinutes = 0;
      
        dataArray.forEach(hourObj => {
          const matchingMinutes = hourObj.minutes.filter(minuteObj => {
            const minutePart = minuteObj.time.split(":")[1];
            return targetMinutes.some(target => target === minutePart) && minuteObj.id !== null;
          });
      
          const greenMatchingMinutes = matchingMinutes.filter(minuteObj => minuteObj.color === "green");
      
          totalMinutes += matchingMinutes.length;
          greenMinutes += greenMatchingMinutes.length;
        });
      
        const greenPercentage = totalMinutes ? (greenMinutes / totalMinutes) * 100 : 0;
      
        // Arredonda para duas casas decimais e adiciona o símbolo de porcentagem
        const formattedGreenPercentage = greenPercentage.toFixed(2) + '%';
      
        return formattedGreenPercentage;
    }


    function onAddMinutesArray(minutes, item) {

        const arr = [...arrayMinutesColorSelect];  
        const index = arr.indexOf(minutes);
        if (index === -1) {
          arr.push(minutes);
        } else {
          arr.splice(index, 1); 
        }
        setMinutesColorSelect(arr); 
        updateColors(); 
        setBarraPercentage(calculateGreenPercentage(dataHourSuperLeague, arr));
    }

    async function updateColors()
    {
        const nextGames = await axios.get(`${ API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES}`, {
            headers: {
                Authorization: AUTHORIZATION
            }
        });
        const arrHourGames = HourHelp.arrHour(dataSuperLeague, strategyComponent.id, showComponent.id, nextGames.data.data);
        const arr = arrHourGames.slice(0, hourFilterComponent.value);
        const arrData = [...arr]; // Cria uma cópia do array arr
        setDataSuperLeague(arrData)
    }


    if(dataHourSuperLeague && dataHourSuperLeague.length > 0)
    {
        return(
            <>
                <ContainerGradientMobile>
    
                    <Container w="100%" display="flex" jc="center" >
    
                        <Text col="#CFFFC2" fs="13px" fw="bold">GREEN: {percentageSuperLeague.green}</Text>
                           
                        <Text col="#FF0000" fs="13px" fw="bold" ml="20px" >RED: {percentageSuperLeague.red}</Text>
    
                    </Container>   
    
                    <Container w="150px" display="flex" jc="ceter" items="center">
                        <ImgChanpionship w="20px" h="20px" src="./images/conmebol-sul-americana.png"/>
                        <Text fs='10px' col="#fff" fw="bold">Super Liga Sul-Americana</Text>
                    </Container>     
    
                </ContainerGradientMobile>
    
                <Container w="98%" pl='3px' display="flex" >
    
                    <Container  w={"4.34%"}>
                        <Container w="100%" display="flex" jc="center" items="center">
                            <Text col="#fff" fs='5px'>G</Text>
                        </Container>
                    </Container>
                            
                    {goalsVertical.map(item=>{
                        return(
                            <Container key={item.id} w={item.width}>
                                <Container w="100%" display="flex" jc="center" items="center">
                                    <Text fs='5px' col="#fff">{HourHelp.getTotalGoalsForMinute(dataHourSuperLeague, item.minutes)}</Text>
                                </Container>
                            </Container>
                        )
                    })}
    
                    <Container  w={"8.68%"}>
                        <Container w="100%" display="flex" jc="center" items="center">
                            <Text col="#fff"></Text>
                        </Container>
                    </Container>
    
                </Container>
    
                <Container pos={"relative"} >
    
                <ContainerOpacity>
    
                    <Container w="100%"  display="flex" >
    
                        <Container w={"4.34%"} h='10px' ></Container>
    
                        {percentualVertical.map(item=>{
                                return(
                                <Container key={item.id} w={item.width} >
                                    <Container w="100%">
                                        <Container w="100%" display="flex" jc="center" items="center">
                                            <Text fs='5px' mt='5px' col={evaluatePercentage(HourHelp.analyzeGreenForMinute(dataHourSuperLeague, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(dataHourSuperLeague, item.minutes).greenCount}</Text>
                                        </Container>
                                        <Container  w="100%" display="flex" jc="center" items="center" mt="5px">
                                            <Text fs='4px' col={evaluatePercentage(HourHelp.analyzeGreenForMinute(dataHourSuperLeague, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(dataHourSuperLeague, item.minutes).greenPercentage}</Text>
                                        </Container>
                                    </Container>
                                </Container>
                                )
                            })}
                        </Container>
                        {arrayMinutesColorSelect.length > 0
                            &&
                            <Container h="15px" w="100%" mb="5px">
                                <Container h="100%" w={barraPercentage} bg="#ccc" display="flex" jc="center" items="center">
                                    <Text fs='5px' fw="bold" col="green">{`${barraPercentage} ${strategyComponent.name}`}</Text>
                                </Container>
                            </Container>
                        }
    
    
                        <Container  display="flex">
    
                            <Container h='100%' w="4.34%" display="flex" jc="center" items="center">
                                <Text fs='5px' col="#FFFF">Hora</Text>
                            </Container>
    
                            {buttonsMinutesVertical.map(item=>{
                                return(
    
                                    <Container key={item.id} w={item.width} display="flex" jc="center" items="center">
                                        <BtnMinutesMobile onClick={()=>{onAddMinutesArray(item.minutes, item)}}>
                                            {item.minutes}
                                        </BtnMinutesMobile>
                                    </Container>
                                )
                            })}
    
                            <Container w="4.34%" display="flex" jc="center" items="center">
                                <Text fs='5px' col="#FFFF">Dados</Text>
                            </Container>
    
                            <Container w="4.34%" display="flex" jc="center" items="center">
                                <Text fs='5px' col="#FFFF"><GiSoccerBall/></Text>
                            </Container>
    
                        </Container>
    
                        {dataHourSuperLeague.map((hour, index)=>{
    
                            return( 
                                <Container h='15px' key={index} display="flex" >
    
                                    <BtnCheckIndicatorMobile w={"4.34%"} bg="#707070">
                                        {/*hour.marked ? <BsCheckSquare color='#fff'/> : <BsSquareFill color='#fff'/>*/}
                                        <Text fs='5px' ml="5px" col='#fff'>{hour.hour}</Text>
                                    </BtnCheckIndicatorMobile>
    
                                    {hour.minutes.map((item,index)=>{
                                        return(
                                        
                                            <TooltipComponentMobile  
                                                key={index}
                                                item={item} 
                                                arrayColorSelect={arrayColorSelect} 
                                                setArrayColorSelect={setArrayColorSelect}
                                                updateColors={updateColors}
                                                arrayMinutesColorSelect={arrayMinutesColorSelect}
                                                setMinutesColorSelect={setMinutesColorSelect}
                                            />
                                        
                                        )
                                    })}
                                    
    
                                    <BoxIndicatorMobile bg={ "#737373"} w="4.34%">
                                        <Text fs='5px' ml="5px" col={evaluatePercentage(hour.greenPercentage)} fw="bold">{hour.greenPercentage}</Text>
                                    </BoxIndicatorMobile>
    
                                    <BoxIndicatorMobile  bg={"#a3a3a3"} w="4.34%">
                                        <Text fs='5px' ml="5px" col='#FFE500' fw="bold">{hour.totalGoals}</Text>
                                    </BoxIndicatorMobile>
    
                                </Container>
                            )
                        })}
    
                    </ContainerOpacity>
    
                    </Container>
            </>
        )
    }else{
        return (
        <Container w="100%" display="flex" jc="center" items="center">
            <ImgChanpionship w="50px" h="50px" src="./images/conmebol-sul-americana.png"/>
            <Text col="#FFFF">Carregando...</Text>
        </Container>
        )
    }




}