import axios from "axios";
import { API_URL_SCRALPER_RANKING, AUTHORIZATION } from "../../config";

export async function onHandleStrategy(item, championsShipName)
{

    let teamsSort;

    const response = await axios.get(`${API_URL_SCRALPER_RANKING}?strategy=${item.id}&championsShip=${championsShipName}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });


    switch (item.id) {

        case 32:
            teamsSort = response.data.slice().sort((a, b) => b.vitorias - a.vitorias);
            break;
        case 33:
            teamsSort = response.data.slice().sort((a, b) => b.empates - a.empates);
            break;                    
        case 34:
            teamsSort = response.data.slice().sort((a, b) => b.derrotas - a.derrotas);
            break;
        case 35:
            teamsSort = response.data.slice().sort((a, b) => b.pontos - a.pontos);
            break;              
        case 37:
            teamsSort = response.data.slice().sort((a, b) => b.vitorias - a.vitorias);
            break;
        case 38:
            teamsSort = response.data.slice().sort((a, b) => b.empates - a.empates);
            break;                    
        case 39:
            teamsSort = response.data.slice().sort((a, b) => b.derrotas - a.derrotas);
            break; 
        case 40:
            teamsSort = response.data.slice().sort((a, b) => b.pontos - a.pontos);
            break; 
        case 42:
            teamsSort = response.data.slice().sort((a, b) => b.vitorias - a.vitorias);
            break;
        case 43:
            teamsSort = response.data.slice().sort((a, b) => b.empates - a.empates);
            break;                    
        case 44:
            teamsSort = response.data.slice().sort((a, b) => b.derrotas - a.derrotas);
            break;  
        case 45:
            teamsSort = response.data.slice().sort((a, b) => b.vitorias - a.vitorias);
            break;
        case 47:
            teamsSort = response.data.slice().sort((a, b) => b.empates - a.empates);
            break;                    
        case 48:
            teamsSort = response.data.slice().sort((a, b) => b.derrotas - a.derrotas);
            break; 
        case 49:
            teamsSort = response.data.slice().sort((a, b) => b.pontos - a.pontos);
        break;            
        case 50:
            teamsSort = response.data.slice().sort((a, b) => b.pontos - a.pontos);
        break;  
        case 52:
            teamsSort = response.data.slice().sort((a, b) => b.pontos - a.pontos);
        break; 
        case 53:
            teamsSort = response.data.slice().sort((a, b) => b.pontos - a.pontos);
        break;                     

        default:
            teamsSort = []
            break;
    }

    return teamsSort;
}

export async function onHandleGames()
{
    const response = await axios.get(`${API_URL_SCRALPER_RANKING}?strategy=32&championsShip=Copa do mundo`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    const teamsSort = response.data.slice().sort((a, b) => b.vitorias - a.vitorias);        

    return teamsSort;
}