import axios from "axios";
import {
     API_URL_SCRALPER_EUROCUP_RESULT, API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES, 
     API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT, 
     API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES, 
     API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT, API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES, 
     API_URL_SCRALPER_WORLDCUP_RESULT, API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES, 
     AUTHORIZATION
    } from "../../config";


export async function getDataWorldCup(lastKnownId)
{
    const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    const gamesResponse = await axios.get(`${API_URL_SCRALPER_WORLDCUP_RESULT}${lastKnownId}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    return {nextGames: nextGames, gamesResponse: gamesResponse}
}

export async function getDataEuroCup(lastKnownId)
{
    const nextGames = await axios.get(`${ API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    const gamesResponse = await axios.get(`${API_URL_SCRALPER_EUROCUP_RESULT}${lastKnownId}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    return {nextGames: nextGames, gamesResponse: gamesResponse}
}

export async function getDataSouthCup(lastKnownId)
{
    const nextGames = await axios.get(`${ API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    const gamesResponse = await axios.get(`${API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT}${lastKnownId}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    return {nextGames: nextGames, gamesResponse: gamesResponse}
}


export async function getDataPremierLeague(lastKnownId)
{
    const nextGames = await axios.get(`${ API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    const gamesResponse = await axios.get(`${API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT}${lastKnownId}`, {
        headers: {
            Authorization: AUTHORIZATION
        }
    });

    return {nextGames: nextGames, gamesResponse: gamesResponse}
}


export function calculetePercentege(arr)
{
    const total = arr.reduce((acc, obj) => {
        acc.greenCount += obj.greenCount;
        acc.redCount += obj.redCount;
        return acc;
    }, { greenCount: 0, redCount: 0 });

    const totalCount = total.greenCount + total.redCount;
    const greenPercentageTotal = ((total.greenCount / totalCount) * 100).toFixed(2) + "%";
    const redPercentageTotal = ((total.redCount / totalCount) * 100).toFixed(2) + "%";

    return {
        greenPercentageTotal:greenPercentageTotal,
        redPercentageTotal:redPercentageTotal
    }
}